module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"Shivraj@1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48cd40afef5913868c8c641e7f866b9e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-reactfire/gatsby-browser.js'),
      options: {"plugins":[],"firebaseConfig":{"apiKey":"AIzaSyDGHpy5No2Op5HH6PrKFkyez7RU-ooUu4A","authDomain":"gs-design-a2ba2.firebaseapp.com","projectId":"gs-design-a2ba2","storageBucket":"gs-design-a2ba2.appspot.com","messagingSenderId":"219403991357","appId":"1:219403991357:web:060660609447278f512d54","measurementId":"G-Y8CT4L51WK"}},
    }]
