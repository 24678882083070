// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-added-product-js": () => import("./../../../src/pages/admin/AddedProduct.js" /* webpackChunkName: "component---src-pages-admin-added-product-js" */),
  "component---src-pages-admin-edit-product-js": () => import("./../../../src/pages/admin/EditProduct.js" /* webpackChunkName: "component---src-pages-admin-edit-product-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-new-registers-js": () => import("./../../../src/pages/admin/NewRegisters.js" /* webpackChunkName: "component---src-pages-admin-new-registers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/ForgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/OurStory.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/Products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-product-js": () => import("./../../../src/pages/Products/Product.js" /* webpackChunkName: "component---src-pages-products-product-js" */),
  "component---src-pages-products-zoom-pane-img-js": () => import("./../../../src/pages/Products/ZoomPaneImg.js" /* webpackChunkName: "component---src-pages-products-zoom-pane-img-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/Register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

